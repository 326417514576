<template>
  <div class="pageContol rollStatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">班级培训统计</a>
          <i>></i>
          <a href="javascript:;" @click="getData()" class="cur-a">监管点名</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          style="
            margin-bottom: 15px;
            font-size: 1rem;
            align-items: flex-start;
            padding-left: 17px;
          "
          class="searchbox"
        >
          <span
            >班级编码：{{
              JSON.parse(this.$route.query.infoData).projectCode
            }}</span
          >
          <span style="margin: 0 15px"
            >班级名称：{{
              JSON.parse(this.$route.query.infoData).projectName
            }}</span
          >
        </div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="学员名称" class="searchboxItem ci-full">
              <span class="listLabel">学员名称:</span>
              <el-input
                v-model="searchData.userName"
                type="text"
                size="small"
                placeholder="请输入学员名称"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="listLabel">身份证号:</span>
              <el-input
                v-model="searchData.idcard"
                type="text"
                size="small"
                placeholder="请输入身份证号码"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="listLabel">手机号:</span>
              <el-input
                v-model="searchData.mobile"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div>
            <div title="组别" class="searchboxItem ci-full">
              <span class="listLabel">组别:</span>
              <el-select
                class="listInput"
                size="small"
                v-model="searchData.groupId"
                placeholder="请选择组别"
                clearable
              >
                <el-option
                  v-for="item in groupIds"
                  :key="item.groupId"
                  :label="item.groupName"
                  :value="item.groupId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top: 15px">
            <div title="认证结果" class="searchboxItem ci-full">
              <span class="listLabel">认证结果:</span>
              <el-select
                class="listInput"
                size="small"
                v-model="searchData.timeState"
                placeholder="请选择认证结果"
                clearable
              >
                <el-option
                  v-for="item in rollResultList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df" style="padding-right: 20px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
            <div class="df" style="padding-right: 20px">
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="exportRegulatoryRollCall"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="组别"
                align="center"
                show-overflow-tooltip
                prop="groupName"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.groupName">{{
                    scope.row.groupName
                  }}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="学员名称"
                align="left"
                show-overflow-tooltip
                minWidth="120"
                prop="userName"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                minWidth="120"
                prop="idcard"
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                minWidth="120"
                prop="mobile"
              />
              <el-table-column
                label="认证次数"
                align="center"
                show-overflow-tooltip
                prop="rk"
              >
                <template slot-scope="scope">
                  <span>{{ "监管点名" + scope.row.rk }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="认证结果"
                align="center"
                show-overflow-tooltip
                prop="timeState"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.timeState == 10">成功</span>
                  <span v-else-if="scope.row.timeState == 20">失败</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="认证模板"
                align="center"
                show-overflow-tooltip
                prop="headPathUrl"
              >
                <template slot-scope="scope">
                  <span v-if="!scope.row.headPathUrl">--</span>
                  <el-image
                    v-else
                    class="theImgno"
                    style="width: 40px; height: 50px"
                    :src="scope.row.headPathUrl"
                    :preview-src-list="[scope.row.headPathUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="点名照片"
                align="center"
                show-overflow-tooltip
                prop="approveFaceUrl"
              >
                <template slot-scope="scope">
                  <span v-if="!scope.row.approveFaceUrl">--</span>
                  <el-image
                    v-else
                    class="theImgno"
                    style="width: 40px; height: 50px"
                    :src="scope.row.approveFaceUrl"
                    :preview-src-list="[scope.row.approveFaceUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="点名时间"
                align="center"
                show-overflow-tooltip
                prop="currentTime"
              >
                <span slot-scope="scope">{{ scope.row.currentTime }}</span>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

export default {
  name: "sjzRegulator/rollStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 查询数据
      searchData: {
        userName: "", // 学员姓名
        idcard: "", // 身份证号
        mobile: "", // 手机号码
        groupId: "", // 组别
        timeState: "", // 认证结果
      },
      // 组别 - 下拉数据
      groupIds: [],
      // 认证结果 - 下拉数据
      rollResultList: [
        {
          value: 10,
          label: "成功",
        },
        {
          value: 20,
          label: "失败",
        },
      ],
    };
  },
  created() {},
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getGroupIds();
    this.getRollTypeList();
  },
  methods: {
    // 获取 - 分组 - 下拉数据
    getGroupIds() {
      this.$post("/biz/roll/group/queryGroupDetail", {
        projectId: JSON.parse(this.$route.query.infoData).projectId,
      }).then((res) => {
        if (res.status == 0) {
          this.groupIds = res.data || [];
        }
      });
    },
    // 获取 - 认证结果 - 下拉数据
    getRollTypeList() {
      const rollTypeList = this.$setDictionary("ROLLTYPE", "list");
      const list = [];
      for (const key in rollTypeList) {
        list.push({
          value: key,
          label: rollTypeList[key],
        });
      }
      this.rollTypeList = list;
    },
    // 获取列表
    getData(pageNum = 1) {
      const params = {
        projectId: JSON.parse(this.$route.query.infoData).projectId,
      };
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.groupId) {
        params.groupId = this.searchData.groupId;
      }
      if (this.searchData.timeState) {
        params.timeState = this.searchData.timeState;
      }
      this.doFetch({
        url: "/gov/project/govRollCallPageList",
        params,
        pageNum,
      });
    },
    // 导出
    exportRegulatoryRollCall() {
      const params = {
        projectId: JSON.parse(this.$route.query.infoData).projectId,
      };
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.groupId) {
        params.groupId = this.searchData.groupId;
      }
      if (this.searchData.timeState) {
        params.timeState = this.searchData.timeState;
      }
      this.$post("/gov/project/supervise_call_roll_detail", params).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          this.editPopupDialogVisible = false;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.theImgno {
  .el-icon-circle-close {
    color: white;
  }
}
</style>